@import 'antd/dist/reset.css';
@import './abstracts/css-variables';
@import './base/reset';
@import './base/global';

img {
  display: block;
  width: 100%;
  height: auto;
}

.ant-modal-footer {
  text-align: center;
}

.ant-table-cell {
  padding: 5px 10px !important;
}

.ant-table-footer {
  padding: 5px 10px !important;
}

.w-full {
  width: 100%;
}

.row-label{
  line-height: 1;
}

.ant-input-number-wrapper.ant-input-number-group{
  height: 100%;

  .ant-input-number-input-wrap,
  input{
    height: 100%;
  }
}

.ant-table-row-selected.custom-table-row {
  font-weight: bold;
}

.ant-table-row.ant-table-row-selected > .ant-table-cell {
  background: rgb(193, 223, 252) !important;
}
