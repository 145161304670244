:root {
  --base-white: #fff;
  --grey-first: #d9d9d9;
  --blue-first: #4096ff;
  --green-first: #00a854;
  --red-first: #f04134;
  --yellow-first: #ff9800;
  --yellow-second: #ff8800;
  --two-colors-blue: #2371d7;
  //--default: #3C4257;
  //--grey: #697386;
  //--grey-primary: #30313D;
  //--grey-second: #6A7383;
  //--grey-disabled: #A3ACBA;
  //--grey-third: #EBEEF1;
  //--grey-fourth: #F6F8FA;
  //--grey-fifth: #EBEEF1;
  //--grey-sixth: #D5DBE1;
  //--grey-seventh: #1A1F36;
  //--grey-eighth: #545A69;
  //--grey-ninth: #E3E8EE;
  //--grey-tenth: #D8DBDF;
  //--grey-eleventh: #4F566B;
  //--grey-twelveth: #E0E6EB;
  //--grey-thirteenth: #878B99;
  //--info: #0570DE;
  //--info-second: #0055BC;
  //--info-third: #CFF5F6;
  //--success: #228403;
  //--success-second: #05690D;
  //--success-third: #D7F7C2;
  //--attention: #C87801;
  //--critical: #DF1B1B;
  //--critical-second: #B3063D;
  //--critical-third: #ff4d4f;
  //--blue-ultra-light: #E6F3FA;
  //--blue-light: #7FCAF1;
  //--blue-mid: #3AA5DC;
  //--blue-dark: #0D7EB7;
  //--blue-ultra-dark: #003366;
  //--ant-yellow-100: #F6E6B9;
  //--yellow-600: #983705;
  //--attention-second: #A82C00;
}
